












import { defineComponent } from '@vue/composition-api'
import { VuetifyThemeVariant } from 'vuetify/types/services/theme'
import { mapActions, mapGetters, mapState } from 'vuex'

export default defineComponent({
  name: 'DashboardIndex',

  components: {
    DashboardCoreAppBar: () => import('./components/core/AppBar.vue'),
    DashboardCoreDrawer: () => import('./components/core/Drawer.vue'),
    DashboardCoreView: () => import('./components/core/View.vue'),
    DashboardCoreMessage: () => import('./components/core/Message.vue'),
  },

  data: () => ({
    expandOnHover: true,
    loadingGlobalState: true,
  }),

  async created () {
    /* load here every cross application data */
    if (this.isUser || this.userConnectedIsRegionManager) {
      await Promise.all([
        this.loadTrainingTypes({
          pagination: false,
        }),
        this.loadNeedTrainingStatuses(),
        this.loadAreas({
          pagination: false,
        }),
        this.loadBusinessIntroducerToyota(),
        this.loadLogisticArea({
          pagination: false,
        }),
        this.loadProfessions({
          pagination: false,
          isActive: true,
        }),
      ])
    }

    if (this.isUser) {
      await Promise.all([
        this.loadConfiguration(),
      ])
    }

    this.loadingGlobalState = false
  },

  computed: {
    ...mapState('user', {
      userConnected: 'userConnected',
    }),
    ...mapGetters('user', {
      userConnectedIsRegionManager: 'isTrainerRegionManager',
      isUser: 'isUser',
    }),
    cssProps () {
      const theme: VuetifyThemeVariant = this.$vuetify.theme.dark ? this.$vuetify.theme.themes.dark : this.$vuetify.theme.themes.light
      return {
        '--background-color': theme.backgroundColor,
        '--primay-color': theme.primary,
        '--secondary-color': theme.secondary,
        '--accent-color': theme.accent,
        '--link-color': theme.link,
        '--info-color': theme.info,
      }
    },
  },
  mounted () {
    if (localStorage.getItem('dark')) {
      this.$vuetify.theme.dark = localStorage.getItem('dark') === 'true'
    }
  },
  watch: {
    '$vuetify.theme.dark' (value) {
      localStorage.setItem('dark', value)
    },
  },

  methods: {
    ...mapActions('status', {
      loadNeedTrainingStatuses: 'load',
    }),
    ...mapActions('trainingLocationList', {
      loadTrainingLocation: 'load',
    }),
    ...mapActions('trainingType', {
      loadTrainingTypes: 'load',
    }),
    ...mapActions('profession', {
      loadProfessions: 'load',
    }),
    ...mapActions('company', {
      loadCompanies: 'load',
      loadBusinessIntroducerToyota: 'loadBusinessIntroducerToyota',
    }),
    ...mapActions('area', {
      loadAreas: 'load',
      loadAreasByTrainer: 'loadByTrainer',
    }),
    ...mapActions('logisticArea', {
      loadLogisticArea: 'load',
    }),
    ...mapActions('configuration', {
      loadConfiguration: 'load',
    }),
  },
})
